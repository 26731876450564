import { AssignmentResources } from "classes/models/assignments/assignment-resource.model";
import { Assignment } from "classes/models/assignments/assignment.model";
import { AssignmentsService } from "classes/models/assignments/assignments.service";
import { Query, collection, query, where } from "firebase/firestore";
import { Subscription, finalize, map } from "rxjs";
import { SubmittedDocument, SubmittedDocuments } from "types/SubmittedDocument";

// @ts-ignore
import { collectionData } from "rxfire/firestore";

export const useAssignmentData = (assignmentId: string) =>
  defineStore(`/assignments/${assignmentId}/data`, () => {
    const gradedDocuments = ref<SubmittedDocument[]>([]);
    const assignment = ref<Assignment>();
    const assignmentResources = ref<AssignmentResources>([]);

    const assignmentsSubscription = ref<Subscription | null>(null);
    const assignmentResourcesSubscription = ref<Subscription | null>(null);

    const isInitialized = ref(false);

    const reset = () => {
      isInitialized.value = false;
      assignment.value = undefined;
      gradedDocuments.value = [];
      assignmentResources.value = [];

      assignmentsSubscription.value?.unsubscribe();
      assignmentResourcesSubscription.value?.unsubscribe();
    };

    const initialize = () => {
      if (isInitialized.value) return;

      const uid = useCurrentUID();

      if (!uid) return;

      isInitialized.value = true;
      assignmentsSubscription.value?.unsubscribe();
      assignmentsSubscription.value = AssignmentsService.streamAssignment(
        assignmentId
      )
        .pipe(
          finalize(() => {
            isInitialized.value = false;
          })
        )
        .subscribe((assignmentData) => {
          assignment.value = assignmentData;
        });
    };

    const isAssignmentResourcesStreamInitialized = ref(false);

    watch(assignment, () => {
      if (isAssignmentResourcesStreamInitialized.value) return;

      if (assignment.value == undefined) return;

      const userId = useCurrentUID();

      if (!userId) return;

      assignmentResourcesSubscription.value?.unsubscribe();
      assignmentResourcesSubscription.value =
        AssignmentsService.streamAssignmentResources(
          assignment.value.classroomId,
          assignmentId,
          userId
        ).subscribe((assignmentResourcesData) => {
          assignmentResources.value = assignmentResourcesData;
        });

      isAssignmentResourcesStreamInitialized.value = true;
    });

    const userDocumentsStore = useUserDocumentsStore();
    const { assignmentUngradedDocuments } = storeToRefs(userDocumentsStore);

    const ungradedDocuments = computed(() => {
      return assignmentUngradedDocuments.value(assignmentId);
    });

    const ungradedFinalDrafts = computed(() => {
      return ungradedDocuments.value.filter(
        (document) =>
          document.documentSubmissionType ===
            DocumentSubmissionType.finalDraft ||
          document.documentSubmissionType == undefined
      );
    });

    const numGradedDocuments = computed(() => {
      return assignment.value?.numGradedDocuments ?? 0;
    });

    const numGradedFinalDraftDocuments = computed(() => {
      return assignment.value?.numGradedFinalDraftDocuments ?? 0;
    });

    const studentSubmittedDocument = computed(() => {
      return (studentId: string) => {
        return (
          ungradedDocuments.value.find(
            (document) => document.studentId === studentId
          ) ??
          gradedDocuments.value.find(
            (document) => document.studentId === studentId
          )
        );
      };
    });

    const hasChecklist = computed(() => {
      if (assignment.value == undefined) return true;

      return assignment.value?.checklistId !== undefined;
    });

    const streamGradedFinalDraftDocuments = () => {
      const db = useFirestore();
      const userId = useCurrentUID();

      if (!userId) {
        throw new Error("User is not authenticated");
      }

      const documentsRef = collection(db, "/documents");
      const queryRef = query(
        documentsRef,
        where("assignmentId", "==", assignmentId),
        where("userId", "==", userId),
        where("state", "==", "graded"),
        where("documentSubmissionType", "==", DocumentSubmissionType.finalDraft)
      ) as Query<SubmittedDocument>;

      return collectionData<SubmittedDocument>(queryRef, {
        idField: "id",
      }).pipe(
        map((documents: SubmittedDocuments) => {
          // I need to sort by submittedAtTimestamp and filter out duplicates for document.studentId

          const sortedDocuments = documents.sort(
            (a, b) =>
              (b.submittedAtTimestamp?.toMillis() ?? 0) -
              (a.submittedAtTimestamp?.toMillis() ?? 0)
          );

          const seenStudentIds = new Set<string>();

          const filteredDocuments = sortedDocuments.filter((document) => {
            if (document.studentId == undefined) return false;

            if (seenStudentIds.has(document.studentId)) {
              return false;
            }

            seenStudentIds.add(document.studentId);

            return true;
          });

          return filteredDocuments;
        })
      );
    };

    const streamGradedDocuments = () => {
      const db = useFirestore();
      const userId = useCurrentUID();

      if (!userId) {
        throw new Error("User is not authenticated");
      }

      const documentsRef = collection(db, "/documents");
      const queryRef = query(
        documentsRef,
        where("assignmentId", "==", assignmentId),
        where("userId", "==", userId),
        where("state", "==", "graded")
      ) as Query<SubmittedDocument>;

      return collectionData<SubmittedDocument>(queryRef, {
        idField: "id",
      });
    };

    const allowKWOSubmission = computed(() => {
      if (assignment.value == undefined) return false;

      const classroomStore = useUserClassroomDataStore(
        assignment.value.classroomId
      );
      const { classroom } = storeToRefs(classroomStore);

      if (assignment.value.allowKWOSubmission == undefined)
        return classroom.value.allowKWOSubmission ?? false;

      return assignment.value.allowKWOSubmission;
    });

    const allowRoughDraftSubmission = computed(() => {
      if (assignment.value == undefined) return false;

      const classroomStore = useUserClassroomDataStore(
        assignment.value.classroomId
      );
      const { classroom } = storeToRefs(classroomStore);

      if (assignment.value.allowRoughDraftSubmission == undefined)
        return classroom.value.allowRoughDraftSubmission ?? false;

      return assignment.value.allowRoughDraftSubmission;
    });
    return {
      initialize,
      ungradedDocuments,
      ungradedFinalDrafts,
      gradedDocuments,
      numGradedDocuments,
      numGradedFinalDraftDocuments,
      assignment,
      hasChecklist,
      studentSubmittedDocument,
      assignmentResources,
      streamGradedDocuments,
      streamGradedFinalDraftDocuments,

      allowKWOSubmission,
      allowRoughDraftSubmission,
      isInitialized,

      reset,
    };
  });
